.alexa-hero {
  overflow: hidden;
  background: #4B4848;
  background-size: cover;
  background-image: url("../images/bgs/alexa.jpg");
  height: 750px;
  position: relative;
  padding-top: 220px;

  @media (max-width: 991px) {
    padding-top: 100px;
    height: 550px;
  }

  
  .container {
    position: relative;
    z-index: 1;
  }

  .hero-text {
    line-height: 49px;
    font-size: 38px;
    font-weight: 300;
    letter-spacing: .3px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    @include animationDuration(1s);

    @media (max-width: 767px) {
      font-size: 27px;
      line-height: 38px;
      margin-top: 0;
      text-align: left;
    }

    @media(min-width: 992px) {
      width: 50%;
    }
  }

  .hero-text-right {
    padding-top: 100px;
    line-height: 49px;
    font-size: 38px;
    font-weight: 300;
    letter-spacing: .3px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    text-align: right;
    color: #5c90b4;

    @include animationDuration(1s);

    @media (max-width: 767px) {
      font-size: 27px;
      line-height: 38px;
      text-align: right;
    }

    @media(min-width: 992px) {
      width: 50%;
    }
  }
}

.alexa-section {
  margin-top: 110px;

  .header {
    text-align: center;
    font-size: 23px;
    color: #656565;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 50px;
  }

  .question {
    width: 90%;

    @media (max-width: 767px) {
      float: none !important;
      margin: 0 auto;
      margin-bottom: 45px;
    }

    .quote-surround {
      color: #53565f;
      line-height: 26px;
      
      padding: 16px 5px;
      position: relative;
      font-size: 24px;
      font-style: bold;
    }

    .quote {      
      color: #53565f;
      line-height: 26px;
      border: 1px solid #E2E2E2;
      padding: 16px 25px;
      border-radius: 5px;
      width: 95%;
      position: relative;
      font-size: 18px;
      font-style: italic;

      @media (max-width: 767px) {
        margin: 0 auto;
        font-size: 16px;
      }

      .arrow-down {
        position: absolute;
        bottom: 3px;
        left: 30px;

        .arrow, .arrow-border {
          border-color: #fff transparent transparent;
          border-style: solid;
          border-width: 11px;
          cursor: pointer;
          position: absolute;
          top: 3px;
          z-index: 1002;
        }

        .arrow-border {
          border-color: #E2E2E2 transparent transparent;
          border-width: 12px;
          top: 3px;
          z-index: 1001;
          left: -1px;
        }
      }
    }

    .author {
      margin-top: 40px;
      margin-left: 10px;

      @media (max-width: 767px) {
        margin-left: 6%;
      }

      .pic {
        width: 71px;
        height: 71px;
        border-radius: 50px;
        float: left;
        position: relative;
        top: -12px;
        margin-right: 18px;
        border: 1px solid #ccc;
      }

      .name {
        color: #3C92C5;
        line-height: 23px;
        font-weight: 500;
      }

      .company {
        font-size: 14px;
        color: #909090;
        line-height: 23px;
        font-weight: 400;
      }
    }
  }
}
