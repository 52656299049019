@mixin recipeApp() {
    position: relative;
    float: left;
    width: 100px;

    &.app-in {
        &:not(.no-arrow) {
            @include shape('triangle', #d6dce0);
        }
    }

    &.app-out {
        margin-left: 32px;
    }

    .app-logo {
        position: relative;
        padding: 0.35rem;
        width: 100%;
        height: 100px;
        background: #f3f6f9;
        border: 1px solid #d6dce0;
        border-radius: 8px;
        overflow: hidden;

        .logo {
            width: 100%;
            height: 100%;
            background-size: auto 100%;
            border-radius: 5px;
        }
    }

    .app-name {
        color: #777777;
        font-size: 0.65em;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.placeholder {
        .app-logo {
            border-style: dashed;

            .logo {
                position: relative;

                &:after {
                    content: '\003f';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: #d6dce0;
                    font-size: 4.2em;
                    font-weight: bold;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &.recipe-app-lg {
        width: 150px;
        height: 169px;

        .app-logo {
            height: 150px;
        }

        .app-name {
            font-size: 1.4em;
        }
    }
}

@mixin shape($shape, $color) {
    @if $shape == 'triangle' {
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 43px;
            right: -28px;
            width: 0;
            height: 0;
            border-top: 10px solid $color;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            transform: rotate(-90deg);
        }
    } @else if $shape == 'arrow' {
        &:after {
            content: '';
            position: relative;
            display: block;
            top: 10px;
            left: 9px;
            width: 7px;
            height: 2px;
            background: $color;
            transform: rotate(45deg) translateX(-50%);
        }

        &:before {
            content: '';
            position: relative;
            display: block;
            top: 11px;
            left: 9px;
            width: 7px;
            height: 2px;
            background: $color;
            transform: rotate(-45deg) translateX(-50%);
        }
    }
}

body {
    &.tennoc-app {
        .gh-ribbon {
            width: 115px;
            text-align: center;
            font-weight: bold;
            font-size: 0.8em;
            padding: 10px;
            text-decoration: none;
            position: absolute;
            top: 60px;
            right: 0;
            background-color: #5b90b4;
            color: #ffffff;
            text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            z-index: 1000;

            &:before, &:after {
                content: "";
                position: relative;
                display: block;
                left: -6px;
                width: 110px;
                border: 1px dashed rgba(255, 255, 255, 0.4);
            }

            &:before {
                bottom: 6px;
            }

            &:after {
                top: 6px;
            }
        }

        .main-header {
            .navbar-brand {
                @media all and (max-width: 992px) {
                    margin: 0 auto;
                }
            }

            .navbar-toggler {
                cursor: pointer;

                .fa {
                    line-height: 30px;
                    width: 30px;
                }
            }
        }

        .main-wrapper {
            width: 100%;
            overflow-x: hidden;

            .main-container {
                width: calc(100% + 568px);
                transition: width 0.2s ease-out;
                overflow-x: hidden;

                .sidebar {
                    position: relative;
                    margin-left: 0;
                    width: 250px;
                    background: #f3f6f9;
                    border-right: 1px solid #dadfe5;
                    transition: margin 0.6s ease-out;

                    .navs {
                        margin-top: 40px;

                        .divider {
                            display: block;
                            margin: 16px 0;
                            width: 100%;
                            height: 1px;
                            background: #d6dce0;
                        }

                        .nav {
                            li {
                                a {
                                    position: relative;
                                    display: block;
                                    padding: 10px 15px 10px 25px;
                                    color: #4a5a63;
                                    background: transparent;
                                    font-weight: 500;
                                    font-size: 13px;
                                    transition: background 0.2s;

                                    &:hover, &:focus, &:active, &.active {
                                        background: #dee7f1;
                                    }

                                    &.dropdown-toggle {
                                        &::after {
                                            position: absolute;
                                            top: 18px;
                                            right: 15px;
                                            transform: rotate(-90deg);
                                        }

                                        &:not(.collapsed) {
                                            &::after {
                                                transform: rotate(0deg);
                                            }
                                        }
                                    }
                                }

                                ul {
                                    li {
                                        a {
                                            padding-left: 40px;
                                        }
                                    }
                                }

                                &.active {
                                    a {
                                        background: #dee7f1;
                                    }
                                }
                            }
                        }
                    }
                }

                .content {
                    position: relative;
                    width: 100%;
                    transition: margin 0.6s ease-out;
                }

                &.sidebar-state {
                    width: 100%;
                    transition-duration: 0.8s;

                    .sidebar {
                        margin-left: -250px;
                    }
                }

                @media all and (min-width: 355px) and (max-width: 991px) {
                    width: calc(100% + 250px);
                    transition-duration: 0.6s;

                    &.sidebar-state {
                        transition-duration: 0.6s;
                    }
                }

                @media all and (min-width: 992px) {
                    width: 100%;

                    .sidebar {
                        margin-left: -250px;
                    }

                    .content {
                        position: relative;
                    }

                    &.sidebar-state {
                        .sidebar {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.recipe-card {
    position: relative;
    background: #f3f6f9;
    border-radius: 8px;

    &.to-connection {
        cursor: pointer;
    }

    .recipe-apps {
            width: 262px;
            min-width: 262px;
            max-width: 262px;

            .recipe-app {
                @include recipeApp();
            }

            &.singular-app {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
    }


    .recipe-title {
        padding-right: 36px;

        h4 {
            color: #777777;
            font-size: 1.3em;
            text-align: left;
        }
    }

    .recipe-date {
        position: absolute;
        top: 12px;
        right: 12px;
        color: #777777;
        font-size: 0.7em;
    }

    .recipe-status {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);

        .color {
            width: 20px;
            height: 20px;
            border-radius: 100%;

            &.green {
                background: #96cb4c;
                border: 1px solid #8bc43d;
                box-shadow: 0 0 7px 2px #b3e074, inset 0 2px 1px -1px rgba(255, 255, 255, 0.8);
            }

            &.yellow {
                background: #f0d775;
                border: 1px solid #e0cc7e;
                box-shadow: 0 0 7px 2px #f0d775, inset 0 2px 1px -1px rgba(255, 255, 255, 0.8);
            }

            &.red {
                background: #f56b65;
                border: 1px solid #fc7d77;
                box-shadow: 0 0 7px 2px #ff9b96, inset 0 2px 1px -1px rgba(255, 255, 255, 0.8);
            }
        }
    }

    @media all and (max-width: 575px) {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}

.action-button {
    position: absolute;
    right: 0;
    top: 50%;
    width: 20px;
    height: 25px;
    background: #d6dce0;
    border-radius: 0 4px 4px 0;
    transform: translate(50%, -50%);

    @include shape('arrow', #777777);

    @media all and (max-width: 575px) {
        width: 20px;
        transform: translate(50%, -50%);

        &:after, &:before {
            left: 9px;
        }
    }
}

.clickable {
    cursor: pointer;
}

.recipe-app {
    @include recipeApp();

    position: initial;
    float: initial;
}

.recipe-apps {
    .recipe-app {
        @include recipeApp();
    }
}

.recipe-hero {
    &:after {
        background: rgba(102, 143, 177, 0.7);
    }
}

.recipe-hero {
    height: 500px;
    background-image: url('#{$imagesPath}/photo-1470753937643-efeb931202a9.jpeg');
    background-size: cover;
    background-position: center center;
    position: relative;

    @media(max-width: 991px) {
        height: 400px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(102, 143, 177, 0.7);
    }

    .container {
        position: relative;
        z-index: 1;

        .row {
            [class^=col] {
                color: #fff;

                a {
                    color: #fff;
                }

                h1 {
                    text-align: center;
                    font-size: 35px;
                    line-height: 46px;
                    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.profile-card {
    position: relative;
    background: #f3f6f9;
    border-radius: 8px;

    .profile-title {
        padding-right: 36px;

        h4 {
            color: #777777;
            font-size: 1.3em;
            text-align: left;
        }
    }

    .profile-date {
        position: absolute;
        top: 12px;
        right: 12px;
        color: #777777;
        font-size: 0.7em;
    }

    .profile-logo {
        position: relative;
        padding: 0.35rem;
        width: 100px;
        height: 100px;
        background: #f3f6f9;
        border: 1px solid #d6dce0;
        border-radius: 8px;
        overflow: hidden;

        .logo {
            width: 100%;
            height: 100%;
            background-size: auto 100%;
            border-radius: 5px;
        }
    }

    @media all and (max-width: 575px) {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}

.profile-hero {
    &:after {
        background: rgba(102, 143, 177, 0.7);
    }
}

.profile-hero {
    height: 400px;
    background-image: url('#{$imagesPath}/photo-1470753937643-efeb931202a9.jpeg');
    background-size: cover;
    background-position: center center;
    position: relative;

    @media(max-width: 991px) {
        height: 400px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(102, 143, 177, 0.7);
    }

    .container {
        position: relative;
        z-index: 1;

        .row {
            [class^=col] {
                color: #fff;

                a {
                    color: #fff;
                }

                h1 {
                    text-align: center;
                    font-size: 35px;
                    line-height: 46px;
                    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.social-buttons {
    text-align: center;

    .social-share {
        font-size: 1.25em;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }

    &.large {
        .social-share {
            font-size: 1.75em;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}

.thin {
    font-weight: 100;
}

.user-avatar {
    position: relative;
    width: auto;
    height: 50px;
    overflow: hidden;
    padding: 0.5rem 0rem 0.5rem 1.5rem !important;

    .avatar-image {
        width: auto;
        height: 100%;
        background-size: auto 100%;
    }
}
